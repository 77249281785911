/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useMemo } from 'react';
import { ColoredState, Search, TableContainer } from '../table/TableContainer';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContentBuilder } from '../ContentBuilder';
import {
    HiddenFilterIdentifier,
    HiddenTableRows,
    InactiveTableRows,
    Table,
    TableColumns,
} from '../../content_builder_inferface/table';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../../keycloak';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Text } from '../Text';
import { colors } from '../../theme/colors';

const hiddenFilterLogic = (
    row: any,
    table: any,
    methods: { [key: string]: any },
    identifier: HiddenFilterIdentifier,
): boolean => {
    const tableElement = table[identifier] as HiddenTableRows | InactiveTableRows;
    const _columnId = tableElement?.column;
    const _columnValue = tableElement?.value;
    const _method = tableElement?.method;
    if (_columnId && _columnId in row) {
        return row[_columnId].toString() === _columnValue;
    } else if (_method && _method in methods) {
        return methods[_method](row) as boolean;
    }
    return true;
};

interface Props {
    table: Table;
    data: any[];
    methods: { [key: string]: any };
}

export const CBTable: FunctionComponent<Props> = (props) => {
    const { table, data, methods } = props;
    const {
        header,
        columns,
        hiddenColumns,
        defaultPageSize,
        coloredState,
        search,
        upperTableButton,
        rowClick,
        isRowClickable = true,
        alternativeUUID,
        initialState,
        disableGlobalFilterColumns,
    } = table;

    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * Basic setup for shown columns in table
     * All fields in a data object will be shown
     */
    let headerColumns: any[] = data.length > 0 ? Object.keys(data[0]) : [];

    /**
     * Override columns in table, based on configuration
     * With columns option you can influence order an shown columns
     */
    if (columns) headerColumns = [...columns];

    /**
     * Filter columns based on accessRole
     */
    headerColumns = headerColumns.filter((item) => {
        if (item.accessRoles && !checkScope(user.roles, item.accessRoles)) return null;
        else return item;
    });

    /**
     * Define upperTableButton if given
     */
    let _upperTableButton;
    if (methods && upperTableButton && upperTableButton.methodName in methods) {
        _upperTableButton = {
            click: methods[upperTableButton.methodName],
            label: upperTableButton.label,
        };
    }

    /**
     * Override default rowClick if given else navigate to details route
     */
    let _rowClick = (item: any) =>
        navigate(`./${alternativeUUID && alternativeUUID in item ? item[alternativeUUID] : item.uuid}`);
    if (methods && rowClick && rowClick in methods) {
        _rowClick = methods[rowClick];
    }

    /** Load configuration (stylings) from header variable */
    const _config = (item: { [key: string]: any } | string) => {
        // On Objects we have to use the header information to get header identifier
        if (typeof item !== 'string') {
            if (typeof item['name'] !== 'string') {
                // Header columns can be calculated by a method. Here we have to use the id inside of the name object
                return header && item['name']['id'] in header ? header[item['name']['id']] : {};
            }
            return header && item['name'] in header ? header[item['name']] : {};
        }
        return header && item in header ? header[item] : {};
    };

    /** Load header column from configuration */
    const _header = (item: { [key: string]: any } | string) => {
        // Cascade object. Use last element for label
        if (item.includes('.')) {
            const lastItem = item.split('.').pop();
            if (lastItem) return t(lastItem);
        }
        // Normal string element
        return `${t(item)}`;
    };

    /** Calculate table column data based on configuration */
    const _getObjectValue = (data: { [key: string]: any }, identifier: string): any => {
        return identifier.split('.').reduce((previous, current) => previous[current], data);
    };

    let _data = data;
    let _dataHidden: any[] = [];
    if (table.hiddenRows) {
        _data = data.filter((row: any) => !hiddenFilterLogic(row, table, methods, HiddenFilterIdentifier.HIDDEN));
        _dataHidden = data.filter((row: any) => hiddenFilterLogic(row, table, methods, HiddenFilterIdentifier.HIDDEN));
    }

    let _dataInactive: any[] = [];
    if (table.inactiveRows) {
        _data = _data.filter((row: any) => !hiddenFilterLogic(row, table, methods, HiddenFilterIdentifier.INACTIVE));
        _dataInactive = _data.filter((row: any) =>
            hiddenFilterLogic(row, table, methods, HiddenFilterIdentifier.INACTIVE),
        );
    }

    const tableData = useMemo(() => _data, [_data]);
    const tableDataHidden = useMemo(() => _dataHidden, [_dataHidden]);
    const tableDataInactive = useMemo(() => _dataInactive, [_dataInactive]);
    const _columns: Array<Column<any>> = useMemo(
        () =>
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            headerColumns.map((item: TableColumns, row) => {
                let id = '';
                let column_header = '';
                if (typeof item.name === 'string') {
                    id = item.name;
                    column_header = item.name;
                } else {
                    if (item.name.label && item.name.label) {
                        column_header = t(item.name.label);
                    }
                    if (item.name.method && item.name.method in methods) {
                        column_header = methods[`${item.name.method}`]();
                    }
                    id = item.name.id;
                }
                const accessorKey = item.searchColumn ? item.searchColumn : id;
                const disableGlobalFilter = disableGlobalFilterColumns?.includes(accessorKey);
                let customCell = null;

                // eslint-disable-next-line react/display-name
                customCell = (cell: any) => {
                    if (item.component) return <ContentBuilder config={[item.component]} data={cell.row.original} />;
                    return <Text>{cell.value}</Text>;
                };

                return {
                    // Only calculate the header string if name is not generated by method
                    Header: typeof item.name === 'string' ? _header(column_header) : column_header,
                    id,
                    accessor: (row: any) => _getObjectValue(row, accessorKey),
                    Cell: customCell,
                    filter: 'custom',
                    disableGlobalFilter,
                    ..._config(item),
                };
            }),
        [_data],
    );

    let coloredStateValues: ColoredState | undefined = undefined;
    if (coloredState) {
        coloredStateValues = {
            show: coloredState['show'],
            method: methods[coloredState.method],
        };
        if (coloredState.reading) {
            coloredStateValues['reading'] = methods[coloredState.reading]();
        }
    }

    let tableSearch: Search = {
        show: search?.show ?? false,
    };

    /**
     * Only display filter if filter.column is in columns
     * Because of user accessRole could be a column dropped from the _column list
     */
    if (search?.filter && _columns.map((item) => item.id).includes(search.filter.column)) {
        tableSearch = {
            ...tableSearch,
            selectConfig: {
                columnAccessor: search.filter.column,
                selectOptions: methods[search?.filter.method](),
            },
        };
    }

    const hiddenRowMethod: any = table.hiddenRows?.toggle ? { method: methods[table.hiddenRows?.toggle] } : {};
    const hiddenRowsVisible = { icon: 'visible', ...hiddenRowMethod };

    return (
        <>
            <TableContainer
                columns={_columns}
                data={tableData}
                hiddenColumns={hiddenColumns}
                defaultPageSize={defaultPageSize}
                search={tableSearch}
                upperTableButton={_upperTableButton}
                rowClick={isRowClickable ? _rowClick : undefined}
                isSelectable={table.select?.show}
                selectCallMethod={table.select?.methodName ? methods[table.select?.methodName] : null}
                coloredState={coloredState?.show && coloredStateValues ? coloredStateValues : undefined}
                initialState={initialState}
                hiddenRows={table.hiddenRows ? hiddenRowsVisible : undefined}
            />
            {table.hiddenRows && tableDataHidden && tableDataHidden.length > 0 && (
                // BorderTop is always displayed, but we don't wont a border. Workaround set color white
                <Accordion mt={4} width="100%" border="0px solid white" allowToggle>
                    <AccordionItem>
                        <AccordionButton color={colors.color8} style={{ padding: 0 }}>
                            <AccordionIcon />
                            <Text pl={2} variant="tabLabel" color={colors.color8}>
                                {t('hiddenTableElements', { count: tableDataHidden.length })}
                            </Text>
                        </AccordionButton>
                        <AccordionPanel style={{ padding: 0 }}>
                            <TableContainer
                                columns={_columns}
                                data={tableDataHidden}
                                hiddenColumns={hiddenColumns}
                                defaultPageSize={defaultPageSize}
                                search={tableSearch}
                                upperTableButton={_upperTableButton}
                                rowClick={isRowClickable ? _rowClick : undefined}
                                isSelectable={table.select?.show}
                                selectCallMethod={table.select?.methodName ? methods[table.select?.methodName] : null}
                                coloredState={coloredState?.show && coloredStateValues ? coloredStateValues : undefined}
                                initialState={initialState}
                                showTableHead={false}
                                hiddenRows={{
                                    icon: 'hidden',
                                    ...hiddenRowMethod,
                                }}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            )}
            {table.inactiveRows && tableDataInactive && tableDataInactive.length > 0 && (
                // BorderTop is always displayed, but we don't wont a border. Workaround set color white
                <Accordion mt={4} width="100%" border="0px solid white" allowToggle>
                    <AccordionItem>
                        <AccordionButton color={colors.color8} style={{ padding: 0 }}>
                            <AccordionIcon />
                            <Text pl={2} variant="tabLabel" color={colors.color8}>
                                {t('inactiveTableElements', { count: tableDataInactive.length })}
                            </Text>
                        </AccordionButton>
                        <AccordionPanel style={{ padding: 0 }}>
                            <TableContainer
                                columns={_columns}
                                data={tableDataInactive}
                                hiddenColumns={hiddenColumns}
                                defaultPageSize={defaultPageSize}
                                search={tableSearch}
                                upperTableButton={_upperTableButton}
                                rowClick={isRowClickable ? _rowClick : undefined}
                                isSelectable={table.select?.show}
                                selectCallMethod={table.select?.methodName ? methods[table.select?.methodName] : null}
                                coloredState={coloredState?.show && coloredStateValues ? coloredStateValues : undefined}
                                initialState={initialState}
                                showTableHead={false}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            )}
        </>
    );
};
