import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { button } from '../theme/components/buttons';

interface Props {
    label: string;
    variant?: string;
    to: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export const RouterLinkButton: FunctionComponent<Props> = (props) => {
    const { label, to, data, variant = 'submit' } = props;
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const variants: any = button.variants;
    const linkColor = variant in variants ? variants[variant]?.color : 'white';
    return (
        <Button variant={variant} px={0}>
            <Link
                style={{
                    padding: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    color: linkColor,
                }}
                to={to}
                state={{
                    ...data,
                }}
            >
                {t(label)}
            </Link>
        </Button>
    );
};
