import React, { FunctionComponent } from 'react';
import { Tabs, TabList, Tab, TabPanel, TabPanels } from '@chakra-ui/react';
import { Text } from '../Text';
import { ContentBuilder } from '../ContentBuilder';
import { Tab as TabInterface } from '../../content_builder_inferface/tab';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../../keycloak';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    tab: TabInterface;
    methods: { [key: string]: () => unknown };
}

export const CBTab: FunctionComponent<Props> = (props) => {
    const { tab, data, methods, ...rest } = props;
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);

    let defaultIndex = 0;
    const filteredTabs = tab.elements.filter((item, index) => {
        // Tab is displayed or not
        if (item.accessRoles && !checkScope(user.roles, item.accessRoles)) return null;
        if (item.viewConditionMethod && item.viewConditionMethod in methods && !methods[item.viewConditionMethod]())
            return null;

        // calculate defaultTabIndex
        if (item.selectedByDefault) {
            defaultIndex = index;
        }

        return item;
    });

    if (tab.defaultTabIndexMethod && tab.defaultTabIndexMethod in methods) {
        const result = methods[tab.defaultTabIndexMethod]() as number;
        defaultIndex = result !== undefined ? result : defaultIndex;
    }

    return (
        tab && (
            <Tabs mt={8} defaultIndex={defaultIndex}>
                <TabList>
                    {filteredTabs.map((item) => (
                        <Tab key={item.name}>
                            <Text variant="tabLabel">{item.name}</Text>
                        </Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {filteredTabs.map((item) => (
                        <TabPanel key={item.name}>
                            <ContentBuilder config={item.component} data={data} methods={methods} {...rest} />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        )
    );
};
