export const convertCurrencyString = (amount: number, currency: string) => {
    let result: string;
    try {
        result = amount.toLocaleString('de-DE', {
            style: 'currency',
            currency: currency,
        });
    } catch (error) {
        // fall back if currency is unkown.
        // We use EUR as currency for formatting and replace it after it with the original currency
        result = amount.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });
        // Here I'm not sure if we always get € as currency. That's why I also replace EUR
        result = result.replace('€', currency).replace('EUR', currency);
    }

    return result;
};
