import React, { FunctionComponent } from 'react';
import { Text } from '../Text';

interface ProfileCardProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export const CBText: FunctionComponent<ProfileCardProps> = (props) => {
    const { data } = props;

    return <Text>{data}</Text>;
};
