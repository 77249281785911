import React, { FunctionComponent } from 'react';
import { Text } from '../Text';
import { colors } from '../../theme/colors';
import { convertCurrencyString } from '../../utils/currency';
import { CBMetaDataChangePercent as Percent } from '../../content_builder_inferface/CBMetaDataChangePercent';
import { AlignRight } from '../AlignRight';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    cBMetaDataChangePercent: Percent;
}

export const CBMetaDataChangePercent: FunctionComponent<Props> = (props) => {
    const { data, cBMetaDataChangePercent } = props;
    const _value = cBMetaDataChangePercent.value in data ? data[`${cBMetaDataChangePercent.value}`] : null;
    let color = '';
    if (data.amount > data.lastAmount) color = colors.positive;
    else if (data.amount < data.lastAmount) color = colors.negative;

    return (
        <AlignRight>
            {_value !== null && <Text color={color}>{convertCurrencyString(_value * 100, '%')}</Text>}
        </AlignRight>
    );
};
