import React, { FunctionComponent, useState } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { MetaData } from '../../content_builder_inferface/metaData';
import {
    AdvisorRequestCustomerReply,
    AgreementProcessMetadataBehaviorType,
    CustomerMetaDataDetailAccount,
    CustomerMetaDataDetailBudget,
    CustomerMetaDataDetailContract,
    MetaDataForCustomer,
} from '../../generated/types';
import { ContentBuilder } from '../ContentBuilder';
import { Text } from '../Text';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';
import { Button } from '../Button';
import { AlignBottom } from '../AlignBottom';
import { Heading } from '../Heading';
import LoadingIndicator from '../LoadingIndicator';
import { DetailsDrawer } from '../DetailsDrawer';
import { MetaDataDetailsElement } from '../MetaDataDetailsElement';

type MetaDetails = CustomerMetaDataDetailContract | CustomerMetaDataDetailBudget | CustomerMetaDataDetailAccount;

interface Data extends MetaDataForCustomer {
    fetching: boolean;
}
interface Props {
    data: Data;
    metaData: MetaData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    methods: { [key: string]: any };
}

export const CBMetaData: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { data, metaData, methods } = props;

    const format = 'DD.MM.YYYY HH:mm:ss';
    let message = t('metaDataNew', { newDate: moment(data.lastAvailableDate).format(format) });
    let requestMessage = t('metaDataRequest');

    const userConsentNeeded =
        data.agreementProcessMetadataBehaviorType === AgreementProcessMetadataBehaviorType.CustomerConsent;

    const newDate = data.lastAvailableDate ? moment(data.lastAvailableDate) : undefined;
    const lastDate = data.lastRequestedDate ? moment(data.lastRequestedDate) : undefined;
    const lastUserRequestedDate = data.lastRequestedFromUserDate ? moment(data.lastRequestedFromUserDate) : undefined;
    const requestPending = data.advisorRequestCustomerReply === AdvisorRequestCustomerReply.Pending;
    const requestDenied = data.advisorRequestCustomerReply === AdvisorRequestCustomerReply.Denied;
    const requestAccepted = data.advisorRequestCustomerReply === AdvisorRequestCustomerReply.Accepted;

    if (lastUserRequestedDate) {
        if (requestPending) {
            requestMessage = t('metaDataRequestPending', {
                lastUserRequestedDate: lastUserRequestedDate.format(format),
            });
        } else if (requestDenied) {
            requestMessage = t('metaDataRequestDenied', {
                lastUserRequestedDate: lastUserRequestedDate.format(format),
            });
        }
    }

    if (newDate && lastDate) {
        message = t('metaDataUpdate', {
            newDate: newDate.format(format),
            lastDate: lastDate.format(format),
        });
    }
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedMetaData, setSelectedMetaData] = useState<{ title: string; data: MetaDetails }>();

    let showBigBox = false;
    if (newDate && lastDate && !lastDate.isSame(newDate, 'minute')) showBigBox = true;
    if (!lastDate && newDate) showBigBox = true;

    const lastBalanceDynamic = () => {
        return t('lastBalanceDynamic', { date: lastDate && lastDate.format('DD.MM.YYYY') });
    };

    const currentBalanceDynamic = () => {
        return t('currentBalanceDynamic', { date: newDate && newDate.format('DD.MM.YYYY') });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const showDetails = (
        data: CustomerMetaDataDetailContract | CustomerMetaDataDetailBudget | CustomerMetaDataDetailAccount,
    ) => {
        let title = '';
        if ('accountName' in data) title = data['accountName'];
        else if ('contractName' in data) title = data['contractName'];
        else if ('name' in data) title = data['name'];

        setSelectedMetaData({ title, data });
        onOpen();
    };

    if (data.fetching) return <LoadingIndicator />;

    return (
        <>
            <Box>
                <Box bg={colors.color2} p={4} borderRadius="md">
                    {userConsentNeeded ? (
                        <>
                            <Flex color="white">
                                <Box flex={1}>
                                    <Heading variant="heading" color="black">
                                        importantNotice
                                    </Heading>
                                    <Text pt={4} flex={1} pr={10}>
                                        {requestMessage}
                                    </Text>
                                </Box>
                                {!requestPending ? (
                                    <AlignBottom>
                                        {metaData.requestDataMethod in methods && (
                                            <Button
                                                variant="submit"
                                                onClick={() => methods[metaData.requestDataMethod]()}
                                            >
                                                requestNewData
                                            </Button>
                                        )}
                                    </AlignBottom>
                                ) : (
                                    ''
                                )}
                            </Flex>
                        </>
                    ) : (
                        ''
                    )}
                    {showBigBox && (
                        <Flex color="white">
                            <Box flex={1}>
                                {!userConsentNeeded ? (
                                    <Heading variant="heading" color="black">
                                        importantNotice
                                    </Heading>
                                ) : (
                                    ''
                                )}
                                <Text pt={4} flex={1} pr={10}>
                                    {message}
                                </Text>
                            </Box>
                            <AlignBottom>
                                {metaData.newDataMethod in methods && (
                                    <Button variant="submit" onClick={() => methods[metaData.newDataMethod]()}>
                                        showNewData
                                    </Button>
                                )}
                            </AlignBottom>
                        </Flex>
                    )}
                    {lastDate && newDate && lastDate.isSame(newDate) && (
                        <Text>
                            {t('metaDataCurrent', {
                                date: newDate.format(format),
                            })}
                        </Text>
                    )}
                </Box>
                {data.lastRequestedMetaData && (
                    <ContentBuilder
                        data={{
                            accounts: data.lastRequestedMetaData.accounts,
                            contracts: data.lastRequestedMetaData.contracts,
                            budgets: data.lastRequestedMetaData.budgets,
                        }}
                        methods={{
                            lastBalanceDynamic,
                            currentBalanceDynamic,
                            ...('do_metaDataIgnore' in methods && { toggleRows: methods['do_metaDataIgnore'] }),
                            showDetails,
                        }}
                        config={metaData.elements}
                    />
                )}
            </Box>
            <DetailsDrawer isOpen={isOpen} title={selectedMetaData?.title} onClose={onClose}>
                {selectedMetaData?.data?.detailsSections.map((section, index) => {
                    const heading =
                        index === 0 ? null : (
                            <Heading mt={index < 1 ? undefined : 10} variant="heading" key={section.title}>
                                {section.title}
                            </Heading>
                        );
                    const result = [
                        heading,
                        ...section.details.map((item) => {
                            return (
                                <MetaDataDetailsElement
                                    key={item.key}
                                    value={item.value}
                                    label={item.key}
                                    valueType={item.valueType}
                                    boxProps={{ mt: 4 }}
                                />
                            );
                        }),
                    ];
                    return result;
                })}
            </DetailsDrawer>
        </>
    );
};
