/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Center, Checkbox } from '@chakra-ui/react';
import React from 'react';

interface Props {
    indeterminate?: boolean;
    name: string;
    checked: boolean;
}

// eslint-disable-next-line react/display-name
export const IndeterminateCheckbox = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ indeterminate, ...rest }: Props, ref) => {
        const defaultRef = React.useRef();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resolvedRef: any = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <Center h="100%">
                <Checkbox ref={resolvedRef} isChecked={rest.checked} {...rest} />
            </Center>
        );
    },
);
