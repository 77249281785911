import { ConfigItem } from '.';
import { DefaultPageSize } from '../components/table/TableContainer';

export interface Table {
    // A column could be a string (also identifier for data) or a object if a Component should be rendered
    columns?: TableColumns[];
    // Table column style configuration. The identifier is the column name you use in columns
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    header?: { [key: string]: any };
    search?: {
        startSearchValue?: string;
        placeholder?: string;
        show: boolean;
        filter?: {
            // value is a method name from screen. This method returns the expected value
            method: string;
            column: string;
        };
    };
    coloredState?: {
        show: boolean;
        // value is a method name from screen. This method returns the expected value
        method: string;
        // value is a method name from screen. This method returns the expected value
        reading?: string;
    };
    select?: { methodName: string; show: boolean }; // default is {show: false}
    hiddenColumns?: string[];
    disableGlobalFilterColumns?: string[];
    defaultPageSize?: DefaultPageSize;
    upperTableButton?: { methodName: string; label: string };
    rowClick?: string;
    alternativeUUID?: string;
    isRowClickable?: boolean; // default is true
    initialState?: {
        [key: string]: [{ id: string; desc: boolean }];
    };
    disableSortBy?: boolean;
    // These rows can be toggled
    hiddenRows?: HiddenTableRows;
    // These rows can't be toggeld
    inactiveRows?: InactiveTableRows;
}

// Enter a column key as string or a column object with a component (eg. CBCounter)
export type TableColumns = TableColumn;

interface TableColumn {
    name: string | CallAbleColumnHeader;
    component?: ConfigItem;
    props?: {
        // any props for column configuration (styles, ...)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    searchColumn?: string;
    accessRoles?: string[];
}

interface CallAbleColumnHeader {
    // Identifier in column object
    id: string;
    // different label. Hardcoded
    label?: string;
    // Return a string for a method to calculate custom heading
    method?: string;
}

export interface HiddenTableRows {
    // Combination of column and value define if a row is shown or not
    column?: string;
    // The value from record will be converted to a string value to compare it
    value?: string;
    // should return boolean if row is hidden or not
    method?: string;
    // toggle visibility of a row
    toggle?: string;
}

export interface InactiveTableRows {
    // Combination of column and value define if a row is shown or not
    column?: string;
    // The value from record will be converted to a string value to compare it
    value?: string;
    // should return boolean if row is hidden or not
    method?: string;
}

export enum HiddenFilterIdentifier {
    HIDDEN = 'hiddenRows',
    INACTIVE = 'inactiveRows',
}
